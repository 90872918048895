import React, { useState, useCallback, useEffect } from 'react'
import { useRouter } from 'next/dist/client/router'
import { NextSeo } from 'next-seo'
import Link from 'next/link'
import Carousel from 'react-simply-carousel'
import { AccordionSummary } from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Image from 'next/image'

import { Column, Row, Button as But } from 'components'

import {
  SectionImage,
  Title,
  H2,
  Container,
  H5,
  H4,
  H6,
  P2,
  Button,
  ButtonView,
  DivButton,
  SectionCompany,
  Sequence,
  Arrow,
  Loan,
  Investment,
  WhoAre,
  Media,
  Depositions,
  ContainerDepositions,
  ImgButtonDeposition,
  CardDeposition,
  WhatsGroup,
  ContainerWhatsGroup,
  ButtonWhatsGroup,
  HR,
  CommonQuestions,
  Accordion,
  Footer,
  FooterBanks,
  FooterLinks,
  FooterInfo,
  WhoAreElement,
  ImageBorder,
  AnswerFQS
} from 'routes/Home/style'

import { useUser } from 'context/user-context'
import { getFQAHome, getImagesHome } from 'services/v3'

const icons = [
  <Image src='/img/GazetaDoPovo.png' alt='media' width='113' height='83' layout='fixed' />,
  <Image src='/img/dci.png' alt='media' width='132' height='45' layout='fixed' />,
  <Image src='/img/Exame.png' alt='media' width='196' height='40' layout='fixed' />,
  <Image src='/img/IstoeDinheiro.png' alt='media' width='167' height='44' layout='fixed' />,
  <Image src='/img/JonalDaGlobo.png' alt='media' width='151' height='103' layout='fixed' />,
  <Image src='/img/Uol.png' alt='media' width='176' height='73' layout='fixed' />,
  <Image src='/img/Veja.png' alt='media' width='133' height='64' layout='fixed' />
]

const iconsMobile = [
  <Image src='/img/GazetaDoPovo.png' alt='media' width='61' height='44' layout='fixed' />,
  <Image src='/img/dci.png' alt='media' width='72' height='24' layout='fixed' />,
  <Image src='/img/Exame.png' alt='media' width='105' height='21' layout='fixed' />,
  <Image src='/img/IstoeDinheiro.png' alt='media' width='90' height='24' layout='fixed' />,
  <Image src='/img/JonalDaGlobo.png' alt='media' width='81.54' height='56' layout='fixed' />,
  <Image src='/img/Uol.png' alt='media' width='96' height='40' layout='fixed' />,
  <Image src='/img/Veja.png' alt='media' width='72' height='34' layout='fixed' />
]

const cards = [
  {
    img: '/img/testimonial/testimonial-1.jpg',
    name: 'Camila B.',
    info: 'Empreendedora',
    desc: 'Invisto com a Kavod porque é uma ótima forma de diversificar o portfólio com baixo risco, além de saber que estou fazendo a diferença na vida dos empreendedores que estão acessando um crédito mais barato e viável para o seu negócio. É uma relação ganha-ganha!'
  },
  {
    img: '/img/testimonial/testimonial-2.jpg',
    name: 'Leonardo D.',
    info: 'Empreendedor',
    desc: 'Como investidor, fiquei impressionado com as taxas quase irresistíveis. Vejo na Kavod a oportunidade de ganhar mais dinheiro investindo e ao mesmo tempo transformar o futuro em algo melhor, pois sei que estou ajudando empreendedores com uma taxa muito melhor que os bancos tradicionais.'
  },
  {
    img: '/img/testimonial/testimonial-3.jpg',
    name: 'Júlia R.',
    info: 'Empreendedora',
    desc: 'A rapidez, facilidade e alta rentabilidade de investir com a Kavod foram o que mais me chamaram a atenção. E somado a isso, saber que é possível contribuir para que empreendedores possam desenvolver seus negócios, é ainda melhor! Tenho certeza que meu investimento está em boas mãos!'
  }
]

const Home = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [depoiments, setDepoiments] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [fqa, setFqa] = useState([])
  const [headerImages, setHeaderImages] = useState([])
  const [bodyImages, setBodyImages] = useState([])

  const { user } = useUser()
  const router = useRouter()
  const { asPath } = useRouter()

  const isMobile = useMediaQuery('(max-width:1150px)')

  const handleInvest = useCallback(() => {
    if (!!user) {
      router.push('/campanhas')
    } else {
      router.push('/login')
    }
  }, [user])

  const handleLoan = useCallback(() => {
    router.push('/solicitar-credito')
  }, [])

  const handleCommonQuestions = useCallback(() => {
    router.push('/perguntas-frequentes')
  }, [])

  const handleChange = useCallback(
    panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    },
    []
  )

  useEffect(() => {
    if (!!user) {
      setIsLogged(true)
    }
  }, [user])

  /* route error handling */
  useEffect(() => {
    if (asPath.startsWith('/campanhas/com-aval-e-garantia/')) {
      return router.push(asPath)
    }

    if (asPath.startsWith('/campanhas/com-aval/')) {
      return router.push(asPath)
    }

    if (
      (/\/campanhas\/\d+\//.test(asPath) && /\/campanhas\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/minhas-indicacoes\/\d+\//.test(asPath) && /\/minhas-indicacoes\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/meus-investimentos\/\d+\//.test(asPath) && /\/meus-investimentos\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/assinaturas\/\d+\//.test(asPath) && /\/assinaturas\/\d+\/[A-Za-z0-9_.-]/.test(asPath))
    ) {
      return router.push('/')
    }

    if (asPath.startsWith('/campanhas')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/meus-investimentos')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/assinaturas')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/minhas-indicacoes')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/admin')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/preencher-dados')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/reset-de-senha')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/preencher-dados')) {
      router.push(asPath)
    }
  })

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        const [{ data: response }, { data: responseImages }] = await Promise.all([getFQAHome(), getImagesHome()])
        if (!!response && response.length > 0) {
          setFqa(response)
        }

        if (!!responseImages && responseImages.length > 0) {
          const headerImg = responseImages.filter(item => item.order !== 0)
          if (headerImg.length > 0) {
            setHeaderImages(headerImg[Math.floor(Math.random() * headerImg.length)])
          }

          setBodyImages(responseImages.filter(item => item.order === 0))
        }
      } catch (error) {}
    }
    fetchInfos()
  }, [])
  return (
    <Container login={isLogged}>
      <NextSeo title={'Kavod Lending | Home'} description='KavodLending | Peer to Peer lending' />
      <SectionImage style={{ backgroundImage: `url('${!!headerImages.photo_url ? headerImages.photo_url : null}')` }}>
        <div>
          <div>
            <Title>
              Investimento,<p> com alto retorno.</p> Empréstimo, <p> com juros baixos.</p>
            </Title>
            <H6>Taxas acessíveis e soluções flexíveis!</H6>
          </div>
          <DivButton>
            <Button color='secondary' onClick={handleInvest}>
              Quero investir
            </Button>
            <Button onClick={handleLoan}>Quero empréstimo</Button>
          </DivButton>
        </div>
      </SectionImage>

      <SectionCompany>
        <Column style={{ alignItems: 'center' }}>
          <Title>É simples e fácil!</Title>
          <H2>Então, como funciona?</H2>
          <Sequence>
            <div className='item'>
              <Image src='/img/paper.svg' alt='paper' width='56' height='56' layout='intrinsic' />
              <H6 style={{ width: '100%', maxWidth: '154px' }}>
                Uma solicitação de empréstimo é criada na plataforma.
              </H6>
            </div>
            <Arrow src='/img/arrow-home.svg' alt='arrow' />
            <div className='item'>
              <Image src='/img/aprove.svg' alt='aprove' width='56' height='56' layout='intrinsic' />
              <H6 style={{ width: '100%', maxWidth: '148px' }}>
                Se aprovada pela Kavod, é oferecida aos investidores.
              </H6>
            </div>
            <Arrow src='/img/arrow-home.svg' alt='arrow' />
            <div className='item'>
              <Image src='/img/peoples.svg' alt='peoples' width='56' height='56' layout='intrinsic' />
              <H6 style={{ width: '100%', maxWidth: '179px' }}>
                Investidores escolhem se querem e quanto querem financiar.
              </H6>
            </div>
            <Arrow src='/img/arrow-home.svg' alt='arrow' />
            <div>
              <div className='item'>
                <Image src='/img/money-home.svg' alt='money' width='56' height='56' layout='intrinsic' />
                <H6 style={{ width: '100%', maxWidth: '140px' }}>
                  Caso financiada, a empresa recebe o seu empréstimo.
                </H6>
              </div>
              <Arrow src='/img/arrow-home.svg' alt='arrow' />
              <div className='item'>
                <Image src='/img/hand-money.svg' alt='money' width='56' height='56' layout='intrinsic' />
                <H6 style={{ width: '100%', maxWidth: '183px' }}>
                  Conforme a empresa paga as parcelas, o investidor recebe o valor proporcional.
                </H6>
              </div>
            </div>
          </Sequence>
        </Column>
      </SectionCompany>

      <Loan>
        <div>
          <Title>Empréstimo para empresas</Title>
          <ul>
            <li>Uma linha de financiamento que cabe no seu bolso.</li>
            <li>Taxas de juros a partir de 0.99% a.m.</li>
            <li>Rápida aprovação e sem burocracia.</li>
          </ul>
          <Button onClick={handleLoan}>Quero empréstimo</Button>
        </div>
        {!!bodyImages[0] && (
          <ImageBorder>
            <Image src={bodyImages[0].photo_url} alt='loan' width='387' height='387' layout='intrinsic' />
          </ImageBorder>
        )}
      </Loan>

      <Investment>
        {!!bodyImages[1] && (
          <ImageBorder>
            <Image src={bodyImages[1].photo_url} alt='invest' width='427' height='427' layout='intrinsic' />
          </ImageBorder>
        )}
        <div>
          <Title>Investimentos rentáveis para o seu patrimônio</Title>
          <ul>
            <li>Retornos que variam de 20% a 45% ao ano.</li>
            <li>Investimentos a partir de R$ 2000,00.</li>
            <li>Sem cobrança de taxas no investimento ou no recebimento das parcelas.</li>
          </ul>
          <Button color='secondary' onClick={handleInvest}>
            Quero investir
          </Button>
        </div>
      </Investment>

      <WhoAre>
        <WhoAreElement pos='left'>
          <Image src='/img/element-home5.png' alt='logo' width='105' height='129' />
        </WhoAreElement>

        <Title>Quem somos</Title>
        <H2>
          Somos uma fintech que veio para ajudar ambos os lados do processo. Investidores que querem rentabilidades
          melhores e empresários que querem juros mais justos. Para isto, ambos buscam alternativas aos bancos
          tradicionais e encontram em empresas como a Kavod uma maneira mais justa e transparente de realizar este
          processo.
        </H2>
        <WhoAreElement pos='rigth'>
          <Image src='/img/element-home5.png' alt='element' width='105' height='129' />
        </WhoAreElement>
      </WhoAre>

      <Media>
        <Title>Na mídia</Title>
        <H2>Acompanhe o que acontece conosco</H2>
        {!isMobile ? (
          <Carousel
            updateOnItemClick
            containerProps={{
              style: {
                width: '100%',
                justifyContent: 'space-evenly'
              }
            }}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: <img src='/img/chevron_right.png' alt='rigthArrow' />,
              style: {
                background: 'transparent'
              }
            }}
            backwardBtnProps={{
              children: <img src='/img/chevron_left.png' alt='leftArrow' />,
              style: {
                background: 'transparent'
              }
            }}
            itemsToShow={4}
            speed={400}
          >
            {icons.map((item, index) => (
              <div className='icons-media' key={index}>
                {item}
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            updateOnItemClick
            containerProps={{
              style: {
                width: '100%',
                justifyContent: 'space-evenly'
              }
            }}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: <img src='/img/chevron_right.png' alt='rigthArrow' />,
              style: {
                background: 'transparent'
              }
            }}
            backwardBtnProps={{
              children: <img src='/img/chevron_left.png' alt='leftArrow' />,
              style: {
                background: 'transparent'
              }
            }}
            itemsToShow={2}
            speed={400}
          >
            {iconsMobile.map((item, index) => (
              <div className='icons-media' key={index}>
                {item}
              </div>
            ))}
          </Carousel>
        )}
      </Media>

      <Depositions>
        <ContainerDepositions>
          <div>
            <Title>Depoimentos</Title>
            <H2>Veja a opinião de quem já mudou sua realidade financeira com a gente.</H2>
          </div>
          {!isMobile ? (
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  width: '100%',
                  justifyContent: 'center',
                  maxWidth: '900px'
                }
              }}
              infinite={false}
              centerMode={true}
              activeSlideIndex={depoiments}
              onRequestChange={setDepoiments}
              forwardBtnProps={{
                children: <ImgButtonDeposition src='/img/chevron_right.png' alt='rigthArrow' />,
                style: {
                  background: 'transparent'
                }
              }}
              backwardBtnProps={{
                children: <ImgButtonDeposition src='/img/chevron_left.png' alt='leftArrow' />,
                style: {
                  background: 'transparent'
                }
              }}
              itemsToShow={2}
              speed={300}
            >
              {Array.from({ length: 3 }).map((item, index) => (
                <div
                  style={{
                    border: '16px solid #ECF9FD',
                    boxSizing: 'border-box'
                  }}
                  key={index}
                >
                  <CardDeposition>
                    <Row alignItems='center'>
                      <img src={cards[index].img} alt='media' />
                      <Column>
                        <H5>{cards[index].name}</H5>
                        <p>{cards[index].info}</p>
                      </Column>
                    </Row>
                    <p style={{ marginTop: '32px' }}>
                      <span>“</span>
                      {cards[index].desc}
                      <span>”</span>
                    </p>
                  </CardDeposition>
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  width: '100%',
                  justifyContent: 'center',
                  maxWidth: '900px'
                }
              }}
              infinite={false}
              centerMode={true}
              activeSlideIndex={depoiments}
              onRequestChange={setDepoiments}
              forwardBtnProps={{
                children: <ImgButtonDeposition src='/img/chevron_right.png' alt='rigthArrow' />,
                style: {
                  background: 'transparent'
                }
              }}
              backwardBtnProps={{
                children: <ImgButtonDeposition src='/img/chevron_left.png' alt='leftArrow' />,
                style: {
                  background: 'transparent'
                }
              }}
              itemsToShow={1}
              speed={300}
            >
              {Array.from({ length: 3 }).map((item, index) => (
                <div
                  style={{
                    border: '16px solid #ECF9FD',
                    boxSizing: 'border-box'
                  }}
                  key={index}
                >
                  <CardDeposition>
                    <Row alignItems='center'>
                      <img src={cards[index].img} alt='media' />
                      <Column>
                        <H5>{cards[index].name}</H5>
                        <p>{cards[index].info}</p>
                      </Column>
                    </Row>
                    <p style={{ marginTop: '32px' }}>
                      <span>“</span>
                      {cards[index].desc}
                      <span>”</span>
                    </p>
                  </CardDeposition>
                </div>
              ))}
            </Carousel>
          )}
        </ContainerDepositions>
      </Depositions>

      <WhatsGroup>
        <ContainerWhatsGroup>
          <div>
            <Title>Novidades pelo Telegram</Title>
            <H2>
              Você sabia? Temos um grupo para divulgar as novidades em relação às nossas campanhas! Se interessou?
              Acesse pelo botão abaixo e fique por dentro!
            </H2>
            <div className='whatsButton'>
              <ButtonWhatsGroup
                variant='outlined'
                onClick={() => {
                  window.open('http://t.me/kavodlending', '_blank')
                }}
              >
                <img src='/img/telegram.png' alt='paper' /> Grupo Telegram
              </ButtonWhatsGroup>
            </div>
          </div>
          <div className='image'>
            <Image src='/img/telegram-iphone.png' alt='Telegram-group' width='213' height='312' />
          </div>
        </ContainerWhatsGroup>
      </WhatsGroup>

      <HR />

      <CommonQuestions>
        <div>
          <Title>Perguntas frequentes</Title>
          <H2>Veja quais são as principais dúvidas</H2>

          <>
            {fqa.map((item, index) => (
              <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)} key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: '40px', color: '#9E9E9E' }} />}>
                  <H4>{item.question}</H4>
                </AccordionSummary>
                <AnswerFQS dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Accordion>
            ))}
          </>

          <ButtonView onClick={handleCommonQuestions}>Ver mais</ButtonView>
        </div>
      </CommonQuestions>

      <HR />

      <FooterBanks>
        <div className='div'>
          <H4>Acelerados por</H4>
          <div>
            <Image src='/img/ahead.png' alt='ahead' width='186' height='70' />
            <Image src='/img/startup.png' alt='startup' width='87' height='47' />
          </div>
        </div>
        <span></span>
        <div className='div'>
          <H4>Correspondente bancário</H4>
          <div>
            <Image src='/img/bmp.png' alt='bmp' width='91' height='49' />
          </div>
        </div>
      </FooterBanks>

      <Footer>
        <div>
          <Row mb='32px'>
            <Image src='/img/logo-kavod-footer.svg' alt='element' width='110' height='48' />
          </Row>
          <FooterLinks>
            <Link href={'/campanhas'}>
              <a>Campanhas</a>
            </Link>
            <Link href={'/parcerias'}>
              <a>Parcerias</a>
            </Link>
            <Link href={'/cadastrar'}>
              <a>Quero investir</a>
            </Link>
            <Link href={'/solicitar-credito'}>
              <a>Quero empréstimo</a>
            </Link>
            <Link href={'/trabalhe-conosco'}>
              <a>Trabalhe conosco</a>
            </Link>
            <Link href={'/fale-conosco'}>
              <a>Contato</a>
            </Link>
          </FooterLinks>

          <hr />

          <FooterInfo>
            <P2>Kavod Lending Tecnologia e Serviços Ltda CNPJ 26.610.266/0001-40</P2>
            <P2>Al Santos, 455 - 16 Andar, Cj 1606 São Paulo, SP - 01419-000</P2>
            <Row style={{ gap: '20px', alignItems: 'center' }}>
              <a href='https://www.facebook.com/kavodlending/' target='_blank'>
                <Image src='/img/face.svg' alt='face' width='12' height='24' layout='fixed' />
              </a>
              <a
                href='https://api.whatsapp.com/send?phone=5511975052863&text=Oi%20KavodLending%21%20Voc%C3%AA%20pode%20me%20ajudar%3F'
                target='_blank'
              >
                <Image src='/img/whats.svg' alt='whats' width='25' height='25' layout='fixed' />
              </a>
              <a href='https://www.instagram.com/kavodlending/' target='_blank'>
                <Image src='/img/insta.svg' alt='instagran' width='30' height='30' layout='fixed' />
              </a>
              <a href='https://www.youtube.com/channel/UCGwiK2YOwBPmx1vVz4PuqMQ' target='_blank'>
                <Image src='/img/youtube.svg' alt='youtube' width='34' height='34' layout='fixed' />
              </a>
              <a href='https://twitter.com/kavodlending' target='_blank'>
                <Image src='/img/twitter.svg' alt='twitter' width='24' height='24' layout='fixed' />
              </a>
              <a href='https://www.linkedin.com/company/kavod-lending/' target='_blank'>
                <Image src='/img/linkedin.svg' alt='linkedin' width='29' height='29' layout='fixed' />
              </a>
            </Row>
          </FooterInfo>

          <hr />

          <Column style={{ gap: '25px' }}>
            <P2>
              <strong>kavodlending.com</strong> pertence à Kavod Lending Tecnologia e Serviços Ltda (“Kavod Lending”). A
              Kavod Lending não é uma instituição financeira e não realiza operações de crédito diretamente, mas um
              correspondente bancário da MONEY PLUS SOCIEDADE DE CREDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO
              PORTE LTDA (“BMP Money Plus”) nos termos do artigo 2°, da resolução CMN n°3.954, de 24 de fevereiro de
              2011. Ouvidoria da instituição financeira: 0800 723 4787. Informações adicionais - Custo Efetivo Total:
              CET mínimo de 1,8% ao mês e máximo de 7%. Exemplo - Empréstmo de R$ 100.000,00 para pagar em 2 anos (24
              meses) - Total de 24 parcelas de R$ 5.349,40. Valor total a pagar igual a R$ 128.385,60 com uma taxa de
              juros de 1,81% ao mês (24.01% ao ano). CET de 2,1% ao mês(28,27% ano).
            </P2>
            <P2>
              A Kavod Lending Tecnologia e Serviços Ltda não realiza operações de financiamento e/ou investimento
              diretamente, tampouco realiza quaisquer outras operações privativas de instituições financeiras. Todos os
              financiamentos e/ou investimentos são realizados por meio da BMP Money Plus, devidamente autorizado pelo
              Banco Central do Brasil a realizar esse tipo de atividade.
            </P2>
          </Column>
        </div>
      </Footer>
    </Container>
  )
}

export default Home
